<template>
<div class="solution">
<!--  导航-->
  <div class="dh">
    <div class="navigation">
      <div class="ico" @click="go()">
        <img class="icon" src="../assets/img15.png">
        <p class="designation">华钛信息</p>
      </div>
      <div class="selectivity">
        <div class="alternative">
          <div class="xzx1 xzxz1">
            <span class="xzxz" @click="hang()">行业方案<span class="xzl"></span></span>
            <div class="nav1 nav">
              <div class="navxx">
                <span>行业方案</span>
                <span>华钛信息致力于帮助各类型企业搭建贴合业务的数据平台目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业。</span>
                <span @click="hang()">前往行业方案 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzData" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img188.png">
              </div>
            </div>
          </div>
          <div class="xzx2 xzxz1">
            <span class="xzxz" @click="zi()">自主产品 <span class="xzl"></span></span>
            <div class="nav2 nav">
              <div class="navxx">
                <span>自主产品</span>
                <span>华钛信息供应商采购平台以为企业降本增效、提高经营效率、提升数据价值为导向，含供应商管理、寻源管理、采购协同、财务协同等模块。</span>
                <span @click="zi()">前往自主产品 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDatas" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img189.png">
              </div>
            </div>
          </div>
          <div class="xzx3 xzxz1">
            <span class="xzxz" @click="fu()">服务范围 <span class="xzl"></span></span>
            <div class="nav3 nav">
              <div class="navxx">
                <span>服务范围</span>
                <span>华钛信息以合作共赢的服务理念为核心，为企业提供优质化服务。</span>
                <span @click="fu()">前往服务范围 <span class="r-jt1"></span></span>
              </div>
              <div class="lin-xx">
                <div class="hz" v-for="(item, index) in hzDataes" :key="index">
                  <span class="lin-cut"></span>
                  <p @click="din(item)">{{item.hzz}}</p>
                </div>
              </div>
              <div class="pt">
                <img src="../assets/img190.png">
              </div>
            </div>
          </div>
          <div class="xzx4 xzxz1" v-for="(item ,index) in newData" :key="index">
            <span class="xzxz" @click="din(item)">{{item.cname}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--第一层-->
  <div class="s-top">
    <div class="zss">
      <div class="t-nr">
        <span class="nr-f">行业解决方案</span>
        <span class="nr-s">华钛信息致力于帮助各类型企业搭建贴合业务的数据平台<br>目前服务的客户涵盖金融、互联网、制造业、零售业、汽车等多个行业</span>
        <div class="xq">
          <span>寻求解决方案</span>
          <span><img src="../assets/img11.png"></span>
        </div>
      </div>
    </div>
  </div>
<!-- 第二层 -->
  <div class="s-second">
    <div class="zss">
      <div class="spreadhead">
        <div class="subhead">
          <div class="moit">
            <img class="img3" src="../assets/img3.png">
            <span>热门行业方案</span>
            <img class="img4" src="../assets/img4.png">
          </div>
        </div>
      </div>
      <div class="jia">
        <div class="jia1">
          <div class="ls1 ls" @click="dian1()">
            <span>金融</span>
            <span>运用领先技术助力金融客户科技提升级，打造云上金<br>融，智创未来</span>
          </div>
          <div class="ls2 ls" @click="dian3()">
            <span>零售</span>
            <span>提供云端大数据产品和服务，助力客户<br>快速获取数据洞察力</span>
          </div>
        </div>
        <div class="jia1">
          <div class="ls3 ls" @click="dian2()">
            <span>制造业</span>
            <span>共享行业运营体验，打造高质量、全方<br>位生态的云服务平台</span>
          </div>
          <div class="ls4 ls" @click="dian4()">
            <span>汽车</span>
            <span>提供云端大数据产品和服务，助力客户快<br>速获取数据洞察力</span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--第三层  -->
  <div class="s-thirdy">
    <div class="bj1">
      <img class="mod13" src="../assets/img35.png">
      <div class="bj2">
        <div class="spreadhead">
          <div class="subhead">
            <div class="moit">
              <img class="img3" src="../assets/img3.png">
              <span>经典案例</span>
              <img class="img4" src="../assets/img4.png">
            </div>
          </div>
        </div>
        <div class="lb">
          <div class="lb1">
            <el-carousel :interval="4000" type="card" height="302px" arrow="always" indicator-position="none" :autoplay="false">
              <el-carousel-item v-for="(item, index) in lbData" :key="index">
                <div class="tmg" @click="din(item)">
                  <img :src="item.imgg" />
                  <span>{{item.mesgg}}</span>
                </div>
              </el-carousel-item>
            </el-carousel>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  第四层-->
  <div class="s-four">
    <div class="spreadhead">
      <div class="subhead">
        <div class="moit">
          <img class="img3" src="../assets/img3.png">
          <span>荣誉证书</span>
          <img class="img4" src="../assets/img4.png">
        </div>
      </div>
    </div>
    <div class="zs">
      <div class="zs1">
        <div class="zs2">
          <div class="zs3" v-for="(item, index) in ZsData" :key="index">
            <el-image
                style=""
                :src="item.zmg"
                :preview-src-list="item.srcList">
            </el-image>
            <span>{{item.zsg}}</span>
          </div>
        </div>
      </div>
    </div>
  </div>
<!--  第五层-->
  <div class="s-five">
    <div class="s-bj">
      <span class="b-a"></span>
      <span class="b-l"></span>
    </div>
    <div class="s-bjs">
      <div class="bk-z">
        <div class="z-a"></div>
        <div class="z-l"></div>
      </div>
    </div>
    <div class="s-bjz">
      <div class="bjz1">
        <div class="bjz-n">
          <div class="nz">
            <div class="tz">
              <img src="../assets/img197.png">
              <span>方案与架构咨询</span>
            </div>
            <span class="nz-s">关于使用场景和技术架构更多咨询，请联系我们的销售和技术支持团队。</span>
            <span class="nz-l">联系我们</span>
          </div>
        </div>
        <div class="bjz-t">
          <img src="../assets/img198.png">
          <img src="../assets/img55.png">
        </div>
      </div>
    </div>
  </div>
  <!--    底层-->
  <div class="ground-floor">
    <div class="fbox">
      <div class="fbox1">
        <div class="fbox2">
          <div class="lianxi">
            <div class="fico">
              <img class="ficon" src="../assets/img2.png">
              <p class="fdesignation">华钛信息</p>
            </div>
            <div class="contway">
              <div class="special">
                <span>联系电话：</span>
                <span>021-55962658</span>
              </div>
              <div class="special">
                <span>商务合作：</span>
                <span>business@huataish.com</span>
              </div>
            </div>
          </div>
          <div class="cut-line"></div>
          <div class="explain">
            <div class="explain1">
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">行业方案</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatas" :key="index">
                      <span @click="din(item)">{{ item.lei }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">自主产品</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatass" :key="index">
                      <span @click="din(item)">{{ item.lei1 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename">服务范围</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDatasss" :key="index">
                      <span @click="din(item)">{{ item.lei2 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="cl()">客户案例</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="hz()">合作伙伴</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataes" :key="index">
                      <span>{{ item.lei3 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
              <div class="rice">
                <div class="rice1">
                  <span class="ricename" @click="xx()">华钛信息</span>
                  <ul>
                    <li class="subclass" v-for="(item, index) in foolDataess" :key="index">
                      <span @click="din(item)">{{ item.lei4 }}</span>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="cut-line1"></div>
        <div class="safety">
          <span>上海总部: 上海市长宁区凯旋路1058号1-2号楼2楼</span>
          <a href="https://beian.miit.gov.cn/" target="_blank">沪ICP备20008244号-1</a>
        </div>
      </div>
    </div>
  </div>
</div>
</template>

<script>
export default {
  name: "Solution",
  data() {
    return {
      cur: 0,
      foolDataes:[],
      foolDataess:[],
      hzData:[
        {
          hzz:"金融行业",
          url:'/FinAnce'
        },
        {
          hzz:"制造行业",
          url:'/Make'
        },
        {
          hzz:"零售行业",
          url:'/ReTail'
        },
        {
          hzz:"汽车行业",
          url:'/AutoMobile'
        },
      ],
      hzDatas:[
        {
          hzz:"供应商管理系统",
          url:'/IndepenDent'
        },
        {
          hzz:"企业数字化平台",
          url:'/IndepenDent'
        },
      ],
      hzDataes:[
        {
          hzz:"定制化开发服务",
          url:'/CustoMization'
        },
        {
          hzz:"产品化解决方案服务",
          url:'/Product'
        },
        {
          hzz:"平台研发",
          url:'/PlatForm'
        },
        {
          hzz:"咨询服务",
          url:'/DigiTization'
        },
        {
          hzz:"运维服务",
          url:'/OperAtion'
        },
        {
          hzz:"外包服务",
          url:'/Epiboly'
        },
      ],
      newData: [
        {
          cname: "客户案例",
          url:'/Clien'
        },
        {
          cname: "合作伙伴",
          url:'/Partner'
        },
        {
          cname: "公司信息",
          url:'/information'
        }
      ],
      loopData: [
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
        {
          icon:require("../assets/img17.png")
        },
      ],
      foolDatas: [
        {
          lei: "金融行业",
          url: '/FinAnce'
        },
        {
          lei: "制造行业",
          url: 'Make'
        },
        {
          lei: "零售行业",
          url: '/ReTail'
        },
        {
          lei: "汽车行业",
          url:'/AutoMobile'
        },
      ],
      foolDatass: [
        {
          lei1: "供应商管理系统",
          url: '/IndepenDent'
        },
        {
          lei1: "企业经营数字化平台",
          url:'/IndepenDent'
        },
      ],
      foolDatasss: [
        {
          lei2: "定制化开发服务",
          url:'/CustoMization'
        },
        {
          lei2: "产品化解决方案服务",
          url:'/Product'
        },
        {
          lei2: "平台研发",
          url:'/PlatForm'
        },
        {
          lei2: "咨询服务",
          url:'/DigiTization'
        },
        {
          lei2: "运营服务",
          url:'/OperAtion'
        },
        {
          lei2: "外包服务",
          url:'/Epiboly'
        },
      ],
      lbData:[
        {
          mesgg:"华钛营销云方案",
          imgg:require("../assets/img170.png"),
          url:'/marketing'
        },
        {
          mesgg:"基金投顾系统",
          imgg:require("../assets/img171.png"),
          url:'/ADVISER'
        },
        {
          mesgg:"数据科学平台",
          imgg:require("../assets/img172.png"),
          url:'/database'
        },
      ],
      ZsData:[
        {
          zmg:require('../assets/img176.png'),
          zsg:"IOS27001证书",
          srcList:[require("../assets/img194.jpg")]
        },
        {
          zmg:require('../assets/img177.png'),
          zsg:"IOS20000证书",
          srcList:[require("../assets/img195.jpg")]
        },
        {
          zmg:require('../assets/img178.png'),
          zsg:"IOS9001证书",
          srcList:[require("../assets/img196.png")]
        },
        {
          zmg:require('../assets/img179.png'),
          zsg:"大数据统计分析证书",
          srcList:[require("../assets/img204.png")]
        },
        {
          zmg:require('../assets/img180.png'),
          zsg:"工作引擎交互证书",
          srcList:[require("../assets/img206.png")]
        },
        {
          zmg:require('../assets/img181.png'),
          zsg:"网页开户系统证书",
          srcList:[require("../assets/img205.png")]
        },
      ]
    }
  },
  methods:{
    din(item){
      console.log(item.url)
      window.open(item.url,'_blank')
    },
    handleCommand(command) {
      window.open(command,'_blank')
    },
    hang(){
      window.open('/Solution','_blank')
    },
    zi(){
      window.open('/IndepenDent','_blank')
    },
    dian1(){
      window.open('/FinAnce','_blank')
    },
    dian2(){
      window.open('/Make','_blank')
    },
    dian3(){
      window.open('/ReTail','_blank')
    },
    dian4(){
      window.open('/AutoMobile','_blank')
    },
    go(){
      window.open('/','_blank')
    },
    fu(){
      window.open('/scope','_blank')
    },
    hz(){
      window.open('/Partner','_blank')

    },
    xx(){
      window.open('/information','_blank')

    },
    cl(){
      window.open('/Clien','_blank')
    }
  }

}
</script>

<style scoped>
.solution{
  display: flex;
  flex-direction: column;
  margin: 0 auto 0;
  width: 100%;
}
.zss{
  height: 100%;
  margin: 0 auto;
}
.zsc{
  width: 1260px;
  margin: 0 auto;
  height: 100%;
}
/*标题*/
.spreadhead {
  width: 100%;
  /*height: 122px;*/
  margin-bottom: 48px;
  /*background: red;*/
}
.spreadhead .subhead {
  display: inline-block;
  width: 100%;
  height: 24px;
  /*background: white;*/
  margin-top: 64px;
  margin-bottom: 12px;
}
.spreadhead .subhead .moit {
  display: flex;
  justify-content: space-between;
  width: 328px;
  height: 24px;
  margin: 0 auto;
  /*background: #124BFF;*/
  font-size: 24px;
  line-height: 24px;
}
.spreadhead .subhead .moit img {
  width: 64px;
  height: 18px;
  margin-top: 3px;
}
.spreadhead .subhead .moit span {
  display: block;
  height: 24px;
  font-size: 24px;
  text-align: center;
  line-height: 24px;
  margin: 0 auto;
  overflow-wrap: break-word;
}
.spreadhead .details {
  display: block;
  /*display: inline-block;*/
  width: 476px;
  height: 22px;
  font-size: 14px;
  text-align: center;
  line-height: 22px;
  color: #666F8F;
  margin: auto;
}
/*第一层*/
.s-top{
  width: 100%;
  height: 526px;
  background-image: url("../assets/img28.png");
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
}
.s-top .t-nr{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 496px;
  height: 212px;
  margin-top: 176px;
}
.s-top .t-nr .nr-f{
  width: 228px;
  height: 53px;
  font-size: 38px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 53px;
}
.s-top .t-nr .nr-s{
  width: 496px;
  height: 56px;
  font-size: 16px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 28px;
}
.s-top .t-nr .xq{
  display: flex;
  width: 150px;
  height: 32px;
  background: #2A5CFF;
  box-shadow: 0px 2px 10px 0px rgba(9, 55, 204, 0.31);
  border: 2px solid #FFFFFF;
}
.s-top .t-nr .xq span:first-child{
  width: 106px;
  height: 33px;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 33px;
  text-align: center;
}
.s-top .t-nr .xq span:last-child{
  flex: 1;
  border-bottom: 34px solid white;
  border-left: 19px solid transparent;
}
.s-top .t-nr .xq span:last-child img{
  display: block;
  width: 20px;
  height: 8px;
  margin-top: 12px;
}
/*第二层*/
.s-second{
  width: 100%;
  height: 572px;
  display: flex;
  flex-direction: column;
}
.s-second .jia{
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 1180px;
  height: 354px;
  margin: 0 auto;
}
.s-second .jia .jia1{
  display: flex;
  justify-content: space-between;
  width: 1180px;
  height: 167px;
}
.s-second .jia .jia1 .ls1{
  width: 580px;
  height: 167px;
  background-image: url("../assets/img169.png");
  box-shadow: 0px 2px 30px 0px rgb(174 163 147 / 50%);
}
.s-second .jia .jia1 .ls2{
  width: 580px;
  height: 167px;
  background-image: url("../assets/img168.png");
  box-shadow: 0px 2px 30px 0px rgb(174 163 147 / 50%);
}
.s-second .jia .jia1 .ls3{
  width: 580px;
  height: 167px;
  background-image: url("../assets/img166.png");
  box-shadow: 0px 2px 30px 0px rgb(174 163 147 / 50%);
}
.s-second .jia .jia1 .ls4{
  width: 580px;
  height: 167px;
  background-image: url("../assets/img167.png");
  box-shadow: 0px 2px 30px 0px rgb(174 163 147 / 50%);
}
.s-second .jia .jia1 .ls{
  display: flex;
  flex-direction: column;
  box-shadow: 3px 3px 16px 0px #DEE3ED;
  cursor: pointer;
}
.s-second .jia .jia1 .ls:hover{
  box-shadow: -3px 0px 10px 10px #AFC4EF;
}
.s-second .jia .jia1 .ls span:first-child{
  height: 28px;
  font-size: 20px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 28px;
  text-shadow: 0px 2px 10px #ACBDE0;
  margin-top: 40px;
  margin-left: 24px;
}
.s-second .jia .jia1 .ls span:last-child{
  width: 418px;
  height: 44px;
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 22px;
  text-shadow: 0px 2px 10px #ACBDE0;
  margin-top: 10px;
  margin-left: 24px;
}
/*第三层*/
.s-thirdy{
  display: flex;
  position: relative;
}
.s-thirdy .bj1{
  width: 100%;
  height: 447px;
  background-color: #F9FAFF;
}
.mod13 {
  z-index: 72;
  position: absolute;
  left: 0;
  top: 0;
  width: 607px;
  height: 447px;
}
.s-thirdy .bj1 .bj2{
  z-index: 1500;
  display: flex;
  flex-direction: column;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: 447px;
}
.s-thirdy .bj1 .lb{
  flex: 1;
}
.s-thirdy .bj1 .lb .lb1{
  width: 1180px;
  height: 300px;
  margin: 0 auto;
}
/*第四层*/
.s-four{
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 426px;
}
.s-four .zs{
  flex: 1;
}
.s-four .zs .zs1{
  display: flex;
  justify-content: space-between;
  width: 1220px;
  height: 196px;
  margin: 0 auto;
  background-color: #F9FAFF;
  box-shadow: 0px 2px 30px 0px rgb(149 147 150 / 50%);
}
.s-four .zs .zs1 .zs2{
  width: 1110px;
  height: 100%;
  display: flex;
  margin: 0 auto;
  justify-content: space-between;
}
.s-four .zs .zs1 .zs2 .zs3{
  display: flex;
  flex-direction: column;
  height: 150px;
  margin-top: 23px;
}
.s-four .zs .zs1 .zs2 .zs3 img{
  display: block;
  width: 82px;
  height: 117px;
  margin: 0 auto;
}
.s-four .zs .zs1 .zs2 .zs3 span{
  text-align: center;
  font-size: 13px;
  margin-top: 6px;
}
/*第五层*/
.s-five{
  width: 100%;
  height: 223px;
  position: relative;
}
.s-five .s-bj{
  width: 100%;
  height: 170px;
  position: absolute;
  bottom: 0px;
}
.s-five .b-a{
  display: inline-block;
  width: 50%;
  height: 100%;
  background-color: #1E232C;
}
.s-five .b-l{
  display: inline-block;
  width: 50%;
  height: 100%;
  background-color: #124BFF;
}
.s-five .s-bjs{
  width: 100%;
  height: 170px;
  position: absolute;
  bottom: 0;
}
.s-five .s-bjs .bk-z{
  width: 1440px;
  height: 100%;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.s-five .s-bjs .bk-z .z-a{
  width: 50%;
  border-top: 170px solid #1E232C;
  border-right: 60px solid transparent;
}
.s-five .s-bjz{
  position: absolute;
  bottom: 0;
  z-index: 100;
  width: 100%;
  height: 170px;
}
.s-five .s-bjz .bjz1{
  width: 1440px;
  height: 170px;
  margin: 0 auto;
  display: flex;
  justify-content: space-between;
}
.s-five .s-bjz .bjz1 .bjz-n{
  width: 50%;
  height: 170px;
}
.s-five .s-bjz .bjz1 .bjz-n .nz{
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  height: 118px;
  width: 448px;
  margin-top: 35px;
  margin-left:130px ;
}
.s-five .s-bjz .bjz1 .bjz-n .nz .tz{
  width: 198px;
  display: flex;
  justify-content: space-between;
}
.s-five .s-bjz .bjz1 .bjz-n .nz .tz img{
  display: inline-block;
  width: 33px;
  height: 32px;
}
.s-five .s-bjz .bjz1 .bjz-n .nz .tz span{
  font-size: 22px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 30px;
}
.s-five .s-bjz .bjz1 .bjz-n .nz .nz-s{
  font-size: 14px;
  font-family: PingFangSC-Regular, PingFang SC;
  font-weight: 400;
  color: #FFFFFF;
  line-height: 24px;
}
.s-five .s-bjz .bjz1 .bjz-n .nz .nz-l{
  width: 124px;
  height: 34px;
  background: #1F55FF;
  font-size: 14px;
  font-family: PingFangSC-Medium, PingFang SC;
  font-weight: 500;
  color: #FFFFFF;
  line-height: 34px;
  text-align: center;
}
.s-five .s-bjz .bjz1 .bjz-t{
  flex: 1;
}
.s-five .s-bjz .bjz1 .bjz-t img:first-child{
  display: inline-block;
  width: 196px;
  height: 208px;
  position: relative;
  bottom: 50px;
  left: 157px;
}
.s-five .s-bjz .bjz1 .bjz-t img:last-child{
  float: right;
  height: 170px;
}
</style>
<style>
.el-dropdown {
  color: black;
  line-height: 60px;
  font-size: 16px;
  margin-right: 74px;
}

.el-button.is-round {
  margin-top: 16px;
  width: 88px;
  text-align: center;
  padding: 0px;
  height: 36px;
  /*background-color: rgba(18, 75, 255, 1);*/
}
.el-carousel__item img {
  width: 100%;
  line-height: 200px;
  margin: 0;
}
.el-carousel__arrow--left{
  border: 1px solid #124BFF;
}
.el-carousel__arrow--right{
  border: 1px solid #124BFF;
}
.el-carousel__arrow i{
  color: white;
}
.tmg{
  position: relative;
  width: 590px;
  height: 302px;
}
.tmg img{
  position: absolute;
  z-index: 100;
}
.tmg span{
  width: 100%;
  height: 20px;
  background-color: black;
  opacity: 0.75;
  color: #FFFFFF;
  text-align: center;
  line-height: 20px;
  font-size: 18px;
  position: absolute;
  bottom: 0px;
  z-index: 10000;
}
.el-image__inner{
  width: 82px;
  display: block;
  height: 117px;
  margin: auto;
}
</style>